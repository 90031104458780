<template>
   <CTabs tabs class="nav-underline nav-underline-primary">
        <CTab active>
          <template slot="title">
            <CIcon name="cil-task" />&nbsp;Filtre
          </template>
          <CForm ref="form" style="height: 100vh; padding: 10px 10px 100px; overflow-y: scroll;">
            <CRow>
              <CCol col="6">
                <CInput
                  label="Sipariş No"
                  placeholder="Sipariş numarası"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.orderId"
                />
              </CCol>
              <CCol col="3" sm="2">
                <CButton
                  type="submit"
                  size="sm"
                  color="primary"
                  @click="filter"
                >
                  <CIcon name="cil-check-circle" />
                  Filtrele
                </CButton>
              </CCol>
              <CCol col="3" sm="2">
                <CButton
                  type="reset"
                  size="sm"
                  color="danger"
                  @click="clearFilters"
                >
                  <CIcon name="cil-ban" />
                  Temizle
                </CButton>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CInput
                  label="Oluşturulma Tarihi"
                  horizontal
                  style="width: 50%"
                  type="date"
                  v-model="data.startTime"
                />
                <CInput
                  label=" "
                  horizontal
                  style="width: 50%"
                  type="date"
                  v-model="data.endTime"
                />
              </CCol>
            </CRow>
            <hr />
            <CRow>
              <CCol sm="6">
                <CInput
                  label="ID"
                  placeholder="Sipariş değişim numarası"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.id"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CInput
                  label="Müşteri"
                  placeholder="Müşteri adı"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.customerFirstName"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  label="Müşteri"
                  placeholder="Müşteri soyadı"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.customerLastName"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CRow>
                  <CCol sm="3"><label>Değişim Durum</label></CCol>
                  <CCol sm="9">
                    <multiselect
                      placeholder="Sipariş durumunu seç"
                      horizontal
                      size="sm"
                      v-model="data.orderChangeStatusType"
                      :options="orderChangeStatusType"
                      :multiple="true"
                      label="label"
                      track-by="label"
                      select-label="Seçiniz"
                      selected-label="Seçildi"
                      deselect-label="Kaldır"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol sm="6">
                <CInput
                  label="Değişim Sayısı"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.changeCount"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CSelect
                  label="İade Hesabı"
                  placeholder="İade Hesabı"
                  horizontal
                  :options="paymentTypes"
                  :value.sync="data.paymentType"
                >
                </CSelect>
              </CCol>
              <CCol sm="6">
                <CInput
                  label="İade Tutarı"
                  placeholder="İade tutarı"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.refundAmount"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CInput
                  label="Alıcı"
                  placeholder="Alıcı adı"
                  horizontal
                  type="text"
                  v-model="data.deliveredPerson"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  label="Takip No"
                  placeholder="Takip numarası"
                  horizontal
                  type="text"
                  v-model="data.trackingNumber"
                />
              </CCol>
            </CRow>
            <CRow style="margin-top: 20px">
              <CCol sm="6">
                <CSelect
                  label="İade Tutarı Farklı"
                  placeholder="0"
                  horizontal
                  :options="comboDataList"
                  :value.sync="data.customRefundFlag"
                >
                </CSelect>
              </CCol>
              <CCol sm="6">
                <CSelect
                  label="İade Eden"
                  placeholder="İade eden"
                  horizontal
                  :options="comboDataList"
                  :value.sync="data.adminApprovedFlag"
                >
                </CSelect>
              </CCol>
            </CRow>
            <CRow style="margin-top: 20px">
              <CCol sm="6">
                <CSelect
                  label="Fatura"
                  placeholder="Fatura"
                  horizontal
                  :options="comboDataList"
                  :value.sync="data.invoicePrintedFlag"
                >
                </CSelect>
              </CCol>
              <CCol sm="6">
                <CSelect
                  label="Bilgilendirme"
                  placeholder="Bilgilendirme"
                  horizontal
                  :options="comboDataList"
                  :value.sync="data.informShippingCompanyTime"
                >
                </CSelect>
              </CCol>
            </CRow>
            <CRow style="margin-top: 20px">
              <CCol sm="6">
                <CSelect
                  label="Kontrol"
                  placeholder="Kontrol"
                  horizontal
                  :options="comboDataList"
                  :value.sync="data.checkedByUser"
                >
                </CSelect>
              </CCol>
            </CRow>
          </CForm>
        </CTab>
    </CTabs>
</template>

<script>
  import Multiselect from 'vue-multiselect'
   export default{
       name: "OrdersFilter",
       props:{
           validated: Boolean,
           params: Object,
           actionType: String,
           filter: Function
       },
       components:{
        Multiselect
       },
       computed:{
        orderStatusTypes: function() {
          let data = []
          this.$store.getters.orderStatusTypes.map(r => data.push({value:r.type, label: r.friendlyType}))
          return data
        },
       },
       watch:{
           params: function(val){
               this.data = {...val};
           },
       },
       data: function(){
           return{
               data: this.params ? {...this.params} : {},
               comboDataList: [
                { label: 'Hepsi', value: '' },
                { label: 'Evet', value: 'true' },
                { label: 'Hayır', value: 'false' }
              ],
              orderChangeStatusType: [
                { label: 'requested', value: 'requested' },
                { label: 'committed', value: 'committed' },
                { label: 'shipped', value: 'shipped' },
                { label: 'tracking', value: 'tracking' },
                { label: 'delivered', value: 'delivered' },
                { label: 'canceled', value: 'canceled' }
              ],
              paymentTypes: [
                { label: 'PayPalEC', value: '1' },
                { label: 'EFT', value: '2' },
                { label: 'CreditCard', value: '3' },
                { label: 'FreePromo', value: '4' },
                { label: 'DebitCard', value: '5' },
                { label: 'IyzicoPay', value: '10' }
              ]
           }
       },
       methods:{
            getCities: async function(){
                let params = {countryId: this.data.countryId}
                await this.$store.dispatch('allCities_list', params)
            },
            clearFilters: function(){
                this.data = {}
                this.filter()
            }
       }
   }
</script>